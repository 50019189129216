import React, { useEffect, useState } from 'react';

import votesOut1 from '../../../assets/badges/Vote-Left-1.png';
import votesOut2 from '../../../assets/badges/Vote-Left-2.png';
import votesOut3 from '../../../assets/badges/Vote-Left-3.png';
import votesOut4 from '../../../assets/badges/Vote-Left-4.png';
import votesOut5 from '../../../assets/badges/Vote-Left-5.png';
import votesOut6 from '../../../assets/badges/Vote-Left-6.png';
import votesIn1 from '../../../assets/badges/Vote-Received-1.png';
import votesIn2 from '../../../assets/badges/Vote-Received-2.png';
import votesIn3 from '../../../assets/badges/Vote-Received-3.png';
import votesIn4 from '../../../assets/badges/Vote-Received-4.png';
import votesIn5 from '../../../assets/badges/Vote-Received-5.png';
import votesIn6 from '../../../assets/badges/Vote-Received-6.png';
import commentsOut1 from '../../../assets/badges/Comments-Left-1.png';
import commentsOut2 from '../../../assets/badges/Comments-Left-2.png';
import commentsOut3 from '../../../assets/badges/Comments-Left-3.png';
import commentsOut4 from '../../../assets/badges/Comments-Left-4.png';
import commentsOut5 from '../../../assets/badges/Comments-Left-5.png';
import commentsOut6 from '../../../assets/badges/Comments-Left-6.png';
import commentsIn1 from '../../../assets/badges/Comments-On-Design-1.png';
import commentsIn2 from '../../../assets/badges/Comments-On-Design-2.png';
import commentsIn3 from '../../../assets/badges/Comments-On-Design-3.png';
import commentsIn4 from '../../../assets/badges/Comments-On-Design-4.png';
import commentsIn5 from '../../../assets/badges/Comments-On-Design-5.png';
import commentsIn6 from '../../../assets/badges/Comments-On-Design-6.png';
import launchedContests1 from '../../../assets/badges/Contest-Launched-1.png';
import launchedContests2 from '../../../assets/badges/Contest-Launched-2.png';
import launchedContests3 from '../../../assets/badges/Contest-Launched-3.png';
import launchedContests4 from '../../../assets/badges/Contest-Launched-4.png';
import launchedContests5 from '../../../assets/badges/Contest-Launched-5.png';
import launchedContests6 from '../../../assets/badges/Contest-Launched-6.png';
import amountOut1 from '../../../assets/badges/Won-Paid-1.png';
import amountOut2 from '../../../assets/badges/Won-Paid-2.png';
import amountOut3 from '../../../assets/badges/Won-Paid-3.png';
import amountOut4 from '../../../assets/badges/Won-Paid-4.png';
import amountOut5 from '../../../assets/badges/Won-Paid-5.png';
import amountOut6 from '../../../assets/badges/Won-Paid-6.png';
import designsOut1 from '../../../assets/badges/Works-Uploaded-1.png';
import designsOut2 from '../../../assets/badges/Works-Uploaded-2.png';
import designsOut3 from '../../../assets/badges/Works-Uploaded-3.png';
import designsOut4 from '../../../assets/badges/Works-Uploaded-4.png';
import designsOut5 from '../../../assets/badges/Works-Uploaded-5.png';
import designsOut6 from '../../../assets/badges/Works-Uploaded-6.png';
import amountIn1 from '../../../assets/badges/Won-Paid-1.png';
import amountIn2 from '../../../assets/badges/Won-Paid-2.png';
import amountIn3 from '../../../assets/badges/Won-Paid-3.png';
import amountIn4 from '../../../assets/badges/Won-Paid-4.png';
import amountIn5 from '../../../assets/badges/Won-Paid-5.png';
import amountIn6 from '../../../assets/badges/Won-Paid-6.png';
import sharesCount1 from '../../../assets/badges/Shares-Done-1.png';
import sharesCount2 from '../../../assets/badges/Shares-Done-2.png';
import sharesCount3 from '../../../assets/badges/Shares-Done-3.png';
import sharesCount4 from '../../../assets/badges/Shares-Done-4.png';
import sharesCount5 from '../../../assets/badges/Shares-Done-5.png';
import sharesCount6 from '../../../assets/badges/Shares-Done-6.png';
import favoritesCount1 from '../../../assets/badges/Favorites-Added-1.png';
import favoritesCount2 from '../../../assets/badges/Favorites-Added-2.png';
import favoritesCount3 from '../../../assets/badges/Favorites-Added-3.png';
import favoritesCount4 from '../../../assets/badges/Favorites-Added-4.png';
import favoritesCount5 from '../../../assets/badges/Favorites-Added-5.png';
import favoritesCount6 from '../../../assets/badges/Favorites-Added-6.png';
import designsIn1 from '../../../assets/badges/Works-On_Contents-1.png';
import designsIn2 from '../../../assets/badges/Works-On_Contents-2.png';
import designsIn3 from '../../../assets/badges/Works-On_Contents-3.png';
import designsIn4 from '../../../assets/badges/Works-On_Contents-4.png';
import designsIn5 from '../../../assets/badges/Works-On_Contents-5.png';
import designsIn6 from '../../../assets/badges/Works-On_Contents-6.png';
import inspirationOut1 from '../../../assets/badges/Inspiration-Created-1.png';
import inspirationOut2 from '../../../assets/badges/Inspiration-Created-2.png';
import inspirationOut3 from '../../../assets/badges/Inspiration-Created-3.png';
import inspirationOut4 from '../../../assets/badges/Inspiration-Created-4.png';
import inspirationOut5 from '../../../assets/badges/Inspiration-Created-5.png';
import inspirationOut6 from '../../../assets/badges/Inspiration-Created-6.png';
import inspirationLikeIn1 from '../../../assets/badges/Inspiration-Liked-Others-1.png';
import inspirationLikeIn2 from '../../../assets/badges/Inspiration-Liked-Others-2.png';
import inspirationLikeIn3 from '../../../assets/badges/Inspiration-Liked-Others-3.png';
import inspirationLikeIn4 from '../../../assets/badges/Inspiration-Liked-Others-4.png';
import inspirationLikeIn5 from '../../../assets/badges/Inspiration-Liked-Others-5.png';
import inspirationLikeIn6 from '../../../assets/badges/Inspiration-Liked-Others-6.png';
import inspirationLikeOut1 from '../../../assets/badges/Inspiration-Liked-Me-1.png';
import inspirationLikeOut2 from '../../../assets/badges/Inspiration-Liked-Me-2.png';
import inspirationLikeOut3 from '../../../assets/badges/Inspiration-Liked-Me-3.png';
import inspirationLikeOut4 from '../../../assets/badges/Inspiration-Liked-Me-4.png';
import inspirationLikeOut5 from '../../../assets/badges/Inspiration-Liked-Me-5.png';
import inspirationLikeOut6 from '../../../assets/badges/Inspiration-Liked-Me-6.png';
import hunter1 from '../../../assets/badges/Client-User-Recruited-1.png';
import hunter2 from '../../../assets/badges/Client-User-Recruited-2.png';
import hunter3 from '../../../assets/badges/Client-User-Recruited-3.png';
import hunter4 from '../../../assets/badges/Client-User-Recruited-4.png';
import hunter5 from '../../../assets/badges/Client-User-Recruited-5.png';
import hunter6 from '../../../assets/badges/Client-User-Recruited-6.png';
//Header Data

import DslStore from '../../../store/DslStore';
import { useStoreState } from 'pullstate';

import styles from '../Profile.module.scss';

import { ContestTag, User, UserBadge } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import PopUpSendMessage from '../../../components/PopUp/PopUpSendMessage/PopUpSendMessage';
import PopUpLogin from '../../../components/PopUp/PopUpLogin/PopUpLogin';
import useSWR from 'swr';

interface Props {
  user: User;
  loggedUser: User | null;
  breadcrumbs: (breadcrumbs: string) => void;
  setPopupMessage: (value: boolean) => void;
}

const BadgesWLF: React.FC<Props> = ({ user, loggedUser, breadcrumbs, setPopupMessage }) => {
  const [userBadges, setUserBadges] = useState<UserBadge[]>([]);
  const { data, error, isLoading } = useSWR('ub|' + user.id, axiosHelper.badgesFetcher);

  const [liked, setLiked] = useState(false);
  const [popUpLogin, setPopupLogin] = useState(false);

  useEffect(() => {
    if (data) {
      console.log('GOT DATA');
      console.log(data);
      setUserBadges(data);
    }
  }, [data]);
  useEffect(() => {
    breadcrumbs('Badge');
    isLiked();
  }, []);

  const isLiked = () => {
    if (user && loggedUser) {
      let state: boolean = false;

      loggedUser?.userType !== 'C' && loggedUser.usersILike.findIndex((item) => (item.id === loggedUser.id ? (state = true) : null));
      setLiked(state);
    }
  };

  const userLiked = () => {
    // if (communityUser?.id && currentUser) {
    //   currentUser.id !== communityUser.id &&
    //     axiosHelper.likeUser(communityUser.id).then((response) => {
    //       setLiked(true);
    //       // alert(`${props.user?.username} added to favorites`);
    //     });
    // }
  };

  const userUnliked = () => {
    // if (communityUser?.id) {
    //   axiosHelper.unlikeUser(communityUser.id).then((response) => {
    //     setLiked(false);
    //     // alert(`${props.user?.username} removed from favorites`);
    //   });
    // }
  };

  const getRightImage = (imageName: string) => {
    //votes out
    if (imageName === 'votesOut1') {
      return votesOut1;
    }
    if (imageName === 'votesOut2') {
      return votesOut2;
    }
    if (imageName === 'votesOut3') {
      return votesOut3;
    }
    if (imageName === 'votesOut4') {
      return votesOut4;
    }
    if (imageName === 'votesOut5') {
      return votesOut5;
    }
    if (imageName === 'votesOut6') {
      return votesOut6;
    }
    //votes in
    if (imageName === 'votesIn1') {
      return votesIn1;
    }
    if (imageName === 'votesIn2') {
      return votesIn2;
    }
    if (imageName === 'votesIn3') {
      return votesIn3;
    }
    if (imageName === 'votesIn4') {
      return votesIn4;
    }
    if (imageName === 'votesIn5') {
      return votesIn5;
    }
    if (imageName === 'votesIn6') {
      return votesIn6;
    }
    //comments Out
    if (imageName === 'commentsOut1') {
      return commentsOut1;
    }
    if (imageName === 'commentsOut2') {
      return commentsOut2;
    }
    if (imageName === 'commentsOut3') {
      return commentsOut3;
    }
    if (imageName === 'commentsOut4') {
      return commentsOut4;
    }
    if (imageName === 'commentsOut5') {
      return commentsOut5;
    }
    if (imageName === 'commentsOut6') {
      return commentsOut6;
    }
    //comments In
    if (imageName === 'commentsIn1') {
      return commentsIn1;
    }
    if (imageName === 'commentsIn2') {
      return commentsIn2;
    }
    if (imageName === 'commentsIn3') {
      return commentsIn3;
    }
    if (imageName === 'commentsIn4') {
      return commentsIn4;
    }
    if (imageName === 'commentsIn5') {
      return commentsIn5;
    }
    if (imageName === 'commentsIn6') {
      return commentsIn6;
    }
    //launched Contests
    if (imageName === 'launchedContests1') {
      return launchedContests1;
    }
    if (imageName === 'launchedContests2') {
      return launchedContests2;
    }
    if (imageName === 'launchedContests3') {
      return launchedContests3;
    }
    if (imageName === 'launchedContests4') {
      return launchedContests4;
    }
    if (imageName === 'launchedContests5') {
      return launchedContests5;
    }
    if (imageName === 'launchedContests6') {
      return launchedContests6;
    }
    //amount Out
    if (imageName === 'amountOut1') {
      return amountOut1;
    }
    if (imageName === 'amountOut2') {
      return amountOut2;
    }
    if (imageName === 'amountOut3') {
      return amountOut3;
    }
    if (imageName === 'amountOut4') {
      return amountOut4;
    }
    if (imageName === 'amountOut5') {
      return amountOut5;
    }
    if (imageName === 'amountOut6') {
      return amountOut6;
    }
    //designs Out
    if (imageName === 'designsOut1') {
      return designsOut1;
    }
    if (imageName === 'designsOut2') {
      return designsOut2;
    }
    if (imageName === 'designsOut3') {
      return designsOut3;
    }
    if (imageName === 'designsOut4') {
      return designsOut4;
    }
    if (imageName === 'designsOut5') {
      return designsOut5;
    }
    if (imageName === 'designsOut6') {
      return designsOut6;
    }
    //amount In
    if (imageName === 'amountIn1') {
      return amountIn1;
    }
    if (imageName === 'amountIn2') {
      return amountIn2;
    }
    if (imageName === 'amountIn3') {
      return amountIn3;
    }
    if (imageName === 'amountIn4') {
      return amountIn4;
    }
    if (imageName === 'amountIn5') {
      return amountIn5;
    }
    if (imageName === 'amountIn6') {
      return amountIn6;
    }
    //shares Count
    if (imageName === 'sharesCount1') {
      return sharesCount1;
    }
    if (imageName === 'sharesCount2') {
      return sharesCount2;
    }
    if (imageName === 'sharesCount3') {
      return sharesCount3;
    }
    if (imageName === 'sharesCount4') {
      return sharesCount4;
    }
    if (imageName === 'sharesCount5') {
      return sharesCount5;
    }
    if (imageName === 'sharesCount6') {
      return sharesCount6;
    }
    //favorites Count
    if (imageName === 'favoritesCount1') {
      return favoritesCount1;
    }
    if (imageName === 'favoritesCount2') {
      return favoritesCount2;
    }
    if (imageName === 'favoritesCount3') {
      return favoritesCount3;
    }
    if (imageName === 'favoritesCount4') {
      return favoritesCount4;
    }
    if (imageName === 'favoritesCount5') {
      return favoritesCount5;
    }
    if (imageName === 'favoritesCount6') {
      return favoritesCount6;
    }
    //designs In
    if (imageName === 'designsIn1') {
      return designsIn1;
    }
    if (imageName === 'designsIn2') {
      return designsIn2;
    }
    if (imageName === 'designsIn3') {
      return designsIn3;
    }
    if (imageName === 'designsIn4') {
      return designsIn4;
    }
    if (imageName === 'designsIn5') {
      return designsIn5;
    }
    if (imageName === 'designsIn6') {
      return designsIn6;
    }
    //inspiration Out
    if (imageName === 'inspirationOut1') {
      return inspirationOut1;
    }
    if (imageName === 'inspirationOut2') {
      return inspirationOut2;
    }
    if (imageName === 'inspirationOut3') {
      return inspirationOut3;
    }
    if (imageName === 'inspirationOut4') {
      return inspirationOut4;
    }
    if (imageName === 'inspirationOut5') {
      return inspirationOut5;
    }
    if (imageName === 'inspirationOut6') {
      return inspirationOut6;
    }
    //inspiration Like In
    if (imageName === 'inspirationLikeIn1') {
      return inspirationLikeIn1;
    }
    if (imageName === 'inspirationLikeIn2') {
      return inspirationLikeIn2;
    }
    if (imageName === 'inspirationLikeIn3') {
      return inspirationLikeIn3;
    }
    if (imageName === 'inspirationLikeIn4') {
      return inspirationLikeIn4;
    }
    if (imageName === 'inspirationLikeIn5') {
      return inspirationLikeIn5;
    }
    if (imageName === 'inspirationLikeIn6') {
      return inspirationLikeIn6;
    }
    //inspiration Like Out
    if (imageName === 'inspirationLikeOut1') {
      return inspirationLikeOut1;
    }
    if (imageName === 'inspirationLikeOut2') {
      return inspirationLikeOut2;
    }
    if (imageName === 'inspirationLikeOut3') {
      return inspirationLikeOut3;
    }
    if (imageName === 'inspirationLikeOut4') {
      return inspirationLikeOut4;
    }
    if (imageName === 'inspirationLikeOut5') {
      return inspirationLikeOut5;
    }
    if (imageName === 'inspirationLikeOut6') {
      return inspirationLikeOut6;
    }
    //hunter
    if (imageName === 'hunter1') {
      return hunter1;
    }
    if (imageName === 'hunter2') {
      return hunter2;
    }
    if (imageName === 'hunter3') {
      return hunter3;
    }
    if (imageName === 'hunter4') {
      return hunter4;
    }
    if (imageName === 'hunter5') {
      return hunter5;
    }
    if (imageName === 'hunter6') {
      return hunter6;
    }
  };

  return (
    <div className="bg-light-gray">
      <main className={`${styles.profileWrapper} container pt-5 mb-5 px-sm-0 `}>
        <div className={`row `}>
          {/* Left column  ====================================================== */}
          <div className="col-12 col-md-3  ">
            {user?.id !== loggedUser?.id && (
              <div className="buttons">
                <button
                  className={`${liked ? 'btn-danger' : 'btn-blue'} rounded full  mb-3`}
                  onClick={() => (loggedUser ? (liked ? userUnliked() : userLiked()) : setPopupLogin(true))}
                >
                  {liked ? 'Remove from favorites' : 'Add to favorite'}
                </button>
                <button className="btn-blue rounded full mb-3" onClick={() => setPopupMessage(true)}>
                  Send message
                </button>

                {popUpLogin && <PopUpLogin popup={popUpLogin} close={() => setPopupLogin(false)} login={(e: boolean) => setPopupLogin(e)} />}
                <button className="btn-red rounded big full mb-3">Block</button>
              </div>
            )}
            <div className={`tags ${user?.id !== loggedUser?.id ? 'mt-5' : ''} d-flex flex-wrap `}>
              {user?.tags &&
                user?.tags.map((item: ContestTag, index: number) => (
                  <>
                    <button className="btn-tag mb-4 ">
                      <span>{item.name.replace(',', '')}</span>
                    </button>
                  </>
                ))}
              {/* <button className="btn-tag mb-4 ">
                <span>Even more looong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button> */}
            </div>
          </div>
          <div className="col-12  col-md-9  px-lg-0  px-sm-4">
            <div className="row text-center ">
              {userBadges.map((badge) => (
                <div className="col-6 col-sm-6 col-md-4 col-lg-3 mb-5 ">
                  <div className={` ${styles.badgePic}`}>
                    <img src={getRightImage(badge.cssClass)} alt="Logo" />
                    {/* <ProfilePic image={getRightImage(badge.cssClass)} /> */}
                  </div>
                  <div className="fw-bold mt-2 ">{badge.name}</div>
                  <div className=" ">{badge.textDescription}</div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="row mt-3 px-4"></div> */}
        </div>

        {/* </div> */}
      </main>
      {/* <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} /> */}
    </div>
  );
};
export default BadgesWLF;
