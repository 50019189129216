import React, { useEffect, FocusEvent } from 'react';
import { ContestTag, DSLTag } from '../../../global';
import styles from './YourProfile.module.scss';
import useSWR from 'swr';
import axiosHelper from '../../../helpers/axiosHelper';

interface TagEditorProps {
  tags: ContestTag[];
  onChange: (tags: ContestTag[]) => void;
  suggestionsGroup?: string;
}

const TagEditor: React.FC<TagEditorProps> = ({ tags, onChange, suggestionsGroup = 'usertags' }) => {
  const { data, error, isLoading } = useSWR(suggestionsGroup, axiosHelper.tagsFetcher);

  const [newTagName, setNewTagName] = React.useState<string>('');
  const [tagString, setTagString] = React.useState<string>(tags.map((tag) => tag.name).join(' '));

  const [allTags, setAllTags] = React.useState<DSLTag[]>([]);
  const [suggestionArray, setSuggestionArray] = React.useState<DSLTag[]>([]);
  const isEditing = React.useRef<boolean>(false);
  const alreadyTyped = React.useRef<string>('');

  useEffect(() => {
    if (data) {
      setAllTags(data);
    }
  }, [data]);

  function removeTag(item: ContestTag) {
    console.log('REMOVE ' + item.name);
  }

  const recalculateTags = React.useCallback(
    (tstr: string) => {
      const textTags = tstr.split(' ');
      const filteredTextTags = textTags.filter((tag) => tag.trim() !== '');
      return filteredTextTags.map((tagName) => {
        const existingTag = tags.find((tag) => tag.name === tagName);
        if (existingTag) {
          return existingTag;
        } else {
          return { type: 'ERTag', name: tagName };
        }
      });
    },
    [tags]
  );


  useEffect(() => {
    console.log('updating tags');
    // onChange(recalculateTags(tagString));
  }, [tagString]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log('handleInputChange');
    isEditing.current = true;
    const inputValue = e.target.value.replace(/[^\w\s]/gi, ''); // Remove punctuation symbols
    setTagString(inputValue);
    const updatedTags = recalculateTags(inputValue);
    const lastTag = updatedTags[updatedTags.length - 1];
    if (lastTag && !lastTag.id) {
      alreadyTyped.current = lastTag.name.toLowerCase();
      const filteredTags = allTags.filter((tag) => tag.name.toLowerCase().startsWith(lastTag.name.toLowerCase()));
      setSuggestionArray(filteredTags);
    } else {
      setSuggestionArray([]);
    }
  };

  const completeSuggestion = (name: string) => {
    console.log('completeSuggestion with ' + name);
    isEditing.current = false;
    var newTagString = tagString.trim();
    const lastIndex = newTagString.lastIndexOf(' ' + alreadyTyped.current);
    if (lastIndex !== -1) {
      newTagString = newTagString.substring(0, lastIndex) + ' ' + name;
    } else {
      newTagString = newTagString + ' ' + name;
    }
    setTagString(newTagString);
    setSuggestionArray([]);
    console.log('completeSuggestion with ' + name + ' and tagString ' + tagString);
    onChange(recalculateTags(newTagString));
  };

  function handleBlur(event: FocusEvent<HTMLTextAreaElement>): void {
    console.log('DID BLUR');
    // setTimeout(() => {
    //   console.log('TIMEOUT');
    //   if (isEditing.current) {
    isEditing.current = false;
    onChange(recalculateTags(tagString));
    setTimeout(() => {
      setSuggestionArray([]);
    }, 1000);
    //   }
    // }, 1000);
    // onChange(recalculateTags(tagString));
  }

  return (
    <>
      <textarea value={tagString} rows={2} placeholder="add a new tag" onChange={handleInputChange} onBlur={handleBlur} />
      {suggestionArray.length > 0 && (
        <div className={`${styles.suggestionWrapper}`}>
          {suggestionArray.map((item: DSLTag, index: number) => (
            <div className={`text ${styles.suggestionRow}`} key={index} onClick={() => completeSuggestion(item.name)}>
              {item.name}
            </div>
          ))}
        </div>
      )}
      <span className=" ms-2 color-dark_grey_40">please separate each tag with spaces</span>
      <div>
        {tags.map(
          (item: ContestTag, index: number) =>
            item && (
              <button
                type="button" // Prevent form submission by setting button type
                className="btn-tag mb-4 "
                key={index}
                onClick={() => removeTag(item)}
              >
                <span>{item.name}</span>
              </button>
            )
        )}
      </div>
    </>
  );
};

export default TagEditor;
