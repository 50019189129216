import React, { useEffect, useRef, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import { clientNavPages, designerNavPages, personalClientNavPages, personalProfileNavPages } from '../../mockdata/navPages';
import { ProfileNavigation } from '../../components/PageNavigation/PROFILE/ProfileNavigation';
import placeholderProfile from '../../assets/Profile.png';

import footerImg from '../../assets/footer_placeholder_transparent.png';

import { getFooterMenu, getHeaderMenu, getWelcomeMessage } from '../../store/Selectors';
import DslStore, { setWelcomeMessage } from '../../store/DslStore';
import { useStoreState } from 'pullstate';

import styles from './Profile.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry, faMapMarkerAlt, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { ProfilePic } from '../../components/Profile/ProfilePic/ProfilePic';
import { ProfileHeader } from '../../components/Profile/ProfileHeader/ProfileHeader';
import { finalSlicedString, fullPathLogo, fullPathLogoUser, locationString, registrationDateString } from '../../helpers/utils';
import UserFavoritesPage from './UserFavorites';
import PortfolioWLF from './SUBPAGES/PortfolioWLF';
import axiosHelper from '../../helpers/axiosHelper';
import { User } from '../../global';
import ProfileEditable from './ProfileEditable';
import DesignerInspirationUpload from './DesignerInspirationUpload';
import { useParams } from 'react-router-dom';
import { INavLink } from '../../global';
import { useAuth } from '../../context/AuthContext';
import useSWR from 'swr';
import AppHeaderWLF from '../../components/AppHeader/AppHeaderWLF';
import ProfileWLF from './ProfileWLF';
import { set } from 'react-hook-form';
import AmbassadorWLF from './SUBPAGES/AmbassadorWLF';
import InspirationsWLF from './SUBPAGES/InspirationsWLF';
import FavoritesWLF from './SUBPAGES/FavoritesWLF';
import BadgesWLF from './SUBPAGES/BadgesWLF';
import AwardsWLF from './SUBPAGES/AwardsWLF';
import { ROUTEPATHS } from '../routePaths';
import { ProfileStatsWLF } from '../../components/Community/ContestStats/ProfileStatsWLF';
import PopUpSendMessage from '../../components/PopUp/PopUpSendMessage/PopUpSendMessage';

const ProfileSkeletonWLF: React.FC = () => {
  const [links, setLinks] = useState<INavLink[]>(designerNavPages);
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);

  let { username } = useParams();
  const { data, error, isLoading } = useSWR('user|' + username, axiosHelper.usersFetcher);
  const { loggedUser } = useAuth();
  const [user, setUser] = useState<User>();
  const { pageName } = useParams();
  const [pageTitle, setPageTitle] = useState<string>(`${ROUTEPATHS.COMMUNITY_PROFILE}`);

  const [editable, setEditable] = useState(false);
  // const communityUser = useStoreState(DslStore, getCommunityUser);
  // const loggedUser = useStoreState(DslStore, getCurrentUser);
  const welcomeMessage = useStoreState(DslStore, getWelcomeMessage);
  const [breadcrumbs, setBreadcrumbs] = useState(`${ROUTEPATHS.COMMUNITY_PROFILE}`);

  const [popupMessage, setPopupMessage] = useState(false);
  const [key, setkey] = useState(0);
  const [messageBanner, setMessageBanner] = useState(0);
  const [successBanner, setSuccessBanner] = useState(0);
  const [image, setImage] = useState(loggedUser?.idLogo);
  const [banner, setBanner] = useState(loggedUser?.idBanner);
  const [profileImage, setProfileImage] = useState<string | undefined>(fullPathLogo(loggedUser?.logoAttachment ? loggedUser?.logoAttachment.s3Path : placeholderProfile));
  const [bannerImage, setBannerImage] = useState<string | undefined>(fullPathLogo(loggedUser?.bannerAttachment ? loggedUser.bannerAttachment.s3Path : undefined));

  const imageAdded = useRef<string>('');

  useEffect(() => {
    if (data) {
      console.log(data);
      setUser(data);
    }
  }, [data]);

  useEffect(() => {
    if (loggedUser) {
      console.log('GOT LOGGED USER');
    }
  }, [loggedUser]);

  useEffect(() => {
    if (pageName) {
      console.log('PAGE NAME ' + pageName);
      setPageTitle(pageName);
      if (pageName === 'profile-editable') {
        setEditable(true);
      } else {
        setEditable(false);
      }
    } else {
      console.log('NO PAGE NAME');
    }
  }, [pageName]);

  const handleImageAdd = (e: string) => {
    console.log(e);
    setImage(e);
    imageAdded.current = e;
    console.log(imageAdded.current);
  };

  const handleBannerAdd = (e: any) => {
    console.log(e);
    // setBannerImage(e);
    setBanner(e);
  };

  return user ? (
    <>
      <div className="bg-light-gray">
        <AppHeaderWLF
          links={headerMenu}
          bgColor="#eab95a"
          logoImg={headerLogo}
          bgImage={headerimg}
          alpha={0.4}
          breadcrumbs={`Home/Community/${username}`}
          pageTitle={breadcrumbs}
          hideSearch={true}
        />
        {user && <ProfileNavigation currentPage={pageTitle} user={user} loggedUser={loggedUser} />}
        <div
          className={`  d-flex flex-row align-items-center w-100 justify-content-center ${welcomeMessage ? `${styles.successBackground}` : 'd-none'}
        `}
        >
          <div className="container d-flex flex-row align-items-center justify-content-center ">
            <div className="row col-12 ">
              <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
                <div className="col-12 text-center align-self-end ">
                  <span className="sub-title color-light">Welcome to Desall!</span>
                </div>
                <div>
                  <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setWelcomeMessage(false)}>
                    <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`  d-flex flex-row align-items-center w-100 justify-content-center ${
            messageBanner === 1 ? `${styles.successBackground}` : messageBanner === 2 ? `${styles.errorBackground}` : 'd-none'
          }
        ${welcomeMessage ? `${styles.translateY60}` : ''}`}
        >
          <div className="container d-flex flex-row align-items-center justify-content-center ">
            <div className="row col-12 ">
              <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
                <div className="col-12 text-center align-self-end ">
                  <span className="sub-title color-light">{messageBanner === 1 ? 'Message sent!' : 'The mailserver is busy, please try again!'}</span>
                </div>
                <div>
                  <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setMessageBanner(0)}>
                    <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`  d-flex flex-row align-items-center w-100 justify-content-center ${
            successBanner === 1 ? `${styles.successBackground}` : successBanner === 2 ? `${styles.errorBackground}` : successBanner === 3 ? `${styles.successBackground}` : 'd-none'
          }
        ${welcomeMessage ? (messageBanner !== 0 ? `${styles.translateY120}` : `${styles.translateY60}`) : messageBanner !== 0 ? `${styles.translateY60}` : ''}`}
        >
          <div className="container  d-flex flex-row align-items-center justify-content-center">
            <div className="row col-12 ">
              <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
                <div className="col-12 text-center align-self-end ">
                  <span className="sub-title color-light">
                    {successBanner === 1 ? 'Updated successfully!' : successBanner === 3 ? 'The password has been changed!' : 'Update Problem! '}
                  </span>
                </div>
                <div>
                  <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setSuccessBanner(0)}>
                    <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <main className={`${styles.profileWrapper} container  px-md-3 pt-5  px-sm-0 `}>
          {(user?.bannerAttachment || editable) && (
            <div className="row ">
              <div className="col-12 px-md-2 mx-md-1 pe-md-3   ">
                <ProfileHeader key={key} editable={editable} bgImage={fullPathLogo(user?.bannerAttachment ? user?.bannerAttachment.s3Path : '')} />
              </div>
            </div>
          )}
          <div className={`row px-4   mt-3 px-sm-0  `}>
            <div className="col-3 "></div>
            <div className="col-md-9 d-sm-none d-md-block">
              <h1 className="bigger-text-bold ms-4 color-blue">{finalSlicedString(user?.username)}</h1>
              <div className="sub-title-editable ms-4 color-dark text-capitalize">{user?.userType === 'D' ? user.job?.name : user?.sector}</div>
            </div>
            <div
              className={`col-12 col-md-3 d-sm-flex align-items-sm-center justify-content-sm-center text-center position-relative ps-md-3 ${styles.profilePic} ${
                user?.bannerAttachment || editable ? styles.picUp : styles.minipicUp
              } `}
            >
              <ProfilePic
                key={key}
                editable={editable}
                image={fullPathLogoUser(user?.logoAttachment?.s3Path)}
                onImageAdd={handleBannerAdd}
                onImageRemove={() => {
                  setBannerImage(undefined);
                }}
              />
            </div>
            <div className="col-12 d-md-none  d-flex mt-10 justify-content-center">
              <div className="text-center">
                <h1 className="bigger-text-bold ms-4 color-blue">{finalSlicedString(user?.username)}</h1>
                <div className="sub-title-editable ms-4 color-dark text-capitalize">{user?.userType === 'D' ? user.job?.name : user?.sector}</div>
              </div>
            </div>

            <div className="col-12 col-md-3 mb-lg-3 ">
              <div className="info ms-4   d-flex flex-column-reverse align-items-center h-100  ">
                <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-5 mb-md-4 mb-3 ">
                  <div className="me-2  d-flex flex-row align-items-center">
                    {user?.userType === 'C' ? (
                      <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue " />
                    ) : (
                      <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-0" />
                    )}
                  </div>
                  <div className="text  col-11">{user?.userType === 'C' ? 'Company' : 'User'}</div>
                </div>
                <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-3 mb-md-4 mb-3  ">
                  <div className="me-2  d-flex flex-row align-items-center">
                    <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue " />
                  </div>
                  <div className="text col-11 ">since {registrationDateString(user?.registrationDate)}</div>
                </div>
                <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-3 mb-md-4  mb-3">
                  {(user?.hasPublicCity || user?.hasPublicCountry) && (
                    <>
                      <div className="me-2  d-flex flex-row align-items-center">
                        <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue " />
                      </div>

                      <div className="text col-11">
                        {user?.hasPublicCity && user.hasPublicCountry && locationString(user?.city, user?.country?.name)}
                        {user?.hasPublicCity && !user.hasPublicCountry && locationString(user?.city)}
                        {!user?.hasPublicCity && user?.hasPublicCountry && locationString(user?.country?.name)}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6  pt-4  ">
              <ProfileStatsWLF user={user} />
            </div>
          </div>
          {popupMessage && (
            <>
              <PopUpSendMessage
                popup={popupMessage}
                close={() => setPopupMessage(false)}
                setMessageNumber={(message) => (setMessageBanner(message), setPopupMessage(false))}
                recipient={user.username}
              />
            </>
          )}

          {pageTitle === `${ROUTEPATHS.COMMUNITY_PROFILE}` && <ProfileWLF user={user} loggedUser={loggedUser} setBreadcrumbs={setBreadcrumbs} setPopupMessage={setPopupMessage} />}
          {pageTitle === 'portfolio' && <PortfolioWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} />}
          {pageTitle === 'ambassador' && <AmbassadorWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} />}
          {pageTitle === 'designer-inspirations' && <InspirationsWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} />}
          {pageTitle === 'user-favorites' && <FavoritesWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} />}
          {pageTitle === 'user-badge' && <BadgesWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} setPopupMessage={setPopupMessage} />}
          {user && <>{editable && <ProfileEditable user={user} callback={setUser} breadcrumbs={setBreadcrumbs} successBanner={(n) => setSuccessBanner(n)} />}</>}
          {pageTitle === 'user-awards' && <AwardsWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} setPopupMessage={setPopupMessage} />}
          {/* {path.includes('new') && <DesignerInspirationUpload breadcrumbs={setBreadcrumbs} />}
          {path.includes('edit') && !path.includes('editable') && <DesignerInspirationUpload breadcrumbs={setBreadcrumbs} />} */}
        </main>
        <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
      </div>
    </>
  ) : (
    <></>
  );
};
export default ProfileSkeletonWLF;
