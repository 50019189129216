import { useState } from 'react';
import styles from './UpdatePassword.module.scss';
import axiosHelper from '../../../helpers/axiosHelper';

import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { User } from '../../../global';

interface ChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const UpdatePasswordWLF: React.FC<{ user: User }> = ({ user }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ChangePasswordFormData>();
  const [opened, setOpened] = useState(false);
  const openClicked = () => {
    console.log('openClicked');
    setOpened(!opened);
  };

  const onSubmit: SubmitHandler<ChangePasswordFormData> = async (data) => {
    try {
      const response = await axiosHelper.changePassword(data.oldPassword, data.newPassword);
      console.log(response);
      alert('Password changed successfully!');
    } catch (error) {
      console.error('Error changing password', error);
      alert('Failed to change password');
    }
  };

  return (
    <>
      <div
        className="col-12  bg-blue mb-5 align-items-center justify-content-center  d-flex"
        style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
        onClick={openClicked}
      >
        <div className="col-2"></div>
        <div className="col-8 justify-content-center d-flex ">
          <span className="text-xxl fw-bold color-light" style={{ fontSize: '2rem' }}>
            Update password
          </span>
        </div>

        <div className={`col-2 d-flex justify-content-end align-items-center ${styles.toggle} ${opened && styles.open}`} onClick={openClicked}>
          <div className={`${styles.arrow} `}> </div>
        </div>
      </div>
      {opened && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Hidden Username Field */}
          <input
            type="hidden"
            value={user.username} // Just bind the username value
            autoComplete="username" // Ensure the username is associated with password
          />

          {/* Old Password */}
          <div className="px-5 mt-7">
            <div className="row mb-5 pt-5">
              <div className="col-12 col-md-2 text fw-bold">Old password</div>
              <div className="col-12 col-md-10">
                <input type="password" autoComplete="current-password" {...register('oldPassword', { required: 'Old password is required' })} />
                {errors.oldPassword && <p>{errors.oldPassword.message}</p>}
              </div>
            </div>

            <div className="row mb-5 mt-4">
              <div className="col-12 col-md-2 text fw-bold">New password</div>
              <div className="col-12 col-md-10">
                <input
                  type="password"
                  autoComplete="new-password"
                  {...register('newPassword', {
                    required: 'New password is required',
                    minLength: { value: 8, message: 'Password must be at least 8 characters' },
                  })}
                />
                {errors.newPassword && <p>{errors.newPassword.message}</p>}
              </div>
            </div>

            <div className="row mb-5 mt-4">
              <div className="col-12 col-md-2 text fw-bold"> Repeat new</div>
              <div className="col-12 col-md-10">
                <input
                  type="password"
                  autoComplete="new-password"
                  {...register('confirmNewPassword', {
                    required: 'Please confirm your new password',
                    validate: (value) => value === watch('newPassword') || 'Passwords do not match',
                  })}
                />
                {errors.confirmNewPassword && <p>{errors.confirmNewPassword.message}</p>}
              </div>
            </div>
            <div className="row  d-flex justify-content-center align-items-center mb-7 mt-4">
              <div className="row justify-content-center">
                <button className={`col-12 col-md-3 my-5 btn-success rounded 3 ${styles.button} `}>update password</button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default UpdatePasswordWLF;
