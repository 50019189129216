import React, { useEffect, useState } from 'react';
import styles from './YourProfile.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { User } from '../../../global';
import CountrySelect from '../../Forms/DropDown/CountrySelect';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import TagEditor from './TagEditor';
import axiosHelper from '../../../helpers/axiosHelper';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface Props {
  user: User;
  callback: (user: User) => void;
}

const YourProfileWLF: React.FC<Props> = ({ user, callback }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Partial<User>>({
    defaultValues: user, // Pre-fill form with the user's data
  });
  const [isEditingDate, setIsEditingDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [month, setMonth] = useState<Date | undefined>(); // Add a month state to control the calendar view
  useEffect(() => {
    if (control._defaultValues?.birthDate) {
      const initialDate = new Date(control._defaultValues.birthDate);
      if (!isNaN(initialDate.getTime())) {
        setSelectedDate(initialDate);
        setMonth(initialDate); // Set the month to the selected date's month
      }
    }
  }, [control]);
  const [opened, setOpened] = useState(false);

  const openClicked = () => {
    console.log('openClicked');
    setOpened(!opened);
  };
  const onSubmit: SubmitHandler<Partial<User>> = async (data) => {
    console.log(data);
    const userdata = await axiosHelper.updateDSLUser(data);
    if (userdata) {
      callback(userdata);
    }
  };
  const gotoProfilePreview: SubmitHandler<Partial<User>> = (data) => {
    window.open(`/${ROUTEPATHS.COMMUNITY}/${user.nameForURL}/profile`, 'blank');
  };

  return (
    <>
      <div
        className="col-12  bg-blue mb-5 align-items-center justify-content-center  d-flex"
        style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
        onClick={openClicked}
      >
        <div className="col-2"></div>
        <div className="col-8  justify-content-center d-flex ">
          <span className="text-xxl fw-bold color-light" style={{ fontSize: '2rem' }}>
            Your profile
          </span>
        </div>

        <div className={`col-2 d-flex  justify-content-end align-items-center ${styles.toggle} ${opened && styles.open}`} onClick={openClicked}>
          <div className={`${styles.arrow}  `}> </div>
        </div>
      </div>
      {opened && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-5 mt-7">
            <div className="row mb-7 mt-4">
              <div className="col-12 col-md-2 text fw-bold"></div>
              <div className="col-12 col-md-10">
                <span className="text color-blue">
                  <i>
                    <b>
                      <span className="color-red">*</span> &nbsp; Mandatory fields (required to {user?.userType === 'D' ? 'upload your design' : 'launch a contest'})
                    </b>
                  </i>
                </span>
              </div>
            </div>
            <div className="row mb-5 mt-4">
              <div className="col-12 col-md-2 text fw-bold">Username</div>
              <div className="col-12 col-md-10">
                <input type="text" style={{ border: '0px ' }} id="username" disabled={true} {...register('username')} />
              </div>
            </div>
            <div className="row mb-5 mt-4">
              <div className="col-12 col-md-2 text fw-bold">Email</div>
              <div className="col-12 col-md-10">
                <input type="text" style={{ border: '0px ' }} id="email" disabled={true} {...register('email')} />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                First name <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input type="text" {...register('firstName', { required: 'First name is required' })} />
                {errors.firstName && <span className="color-red">{errors.firstName.message}</span>}
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Last name <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input type="text" {...register('lastName', { required: 'Last name is required' })} />
                {errors.lastName && <span className="color-red">{errors.lastName.message}</span>}
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input type="checkbox" className="flex-shrink-none" {...register('hasPublicName')} />
                <span className="align-self-middle ps-3 ">Public (your full name visible in your profile page)</span>
              </div>
            </div>
            {user?.userType === 'D' && (
              <>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Biography</div>
                  <div className="col-12 col-md-10">
                    <textarea
                      placeholder={'Please write here a short bio'}
                      rows={7}
                      className="d-flex flex-col justify-content-center align-items-center "
                      {...register('biography')}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">
                    Birth date <span className="color-red">*</span>
                  </div>
                  <div className="col-12 col-md-10">
                    <Controller
                      name="birthDate"
                      control={control}
                      rules={{ required: 'Birthdate is required' }} // Set field as required with a custom message
                      render={({ field: { onChange, value } }) => {
                        const today = new Date();
                        const maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                        return (
                          <>
                            {!isEditingDate ? (
                              <input
                                type="text"
                                id="birthDate"
                                value={selectedDate ? selectedDate.toLocaleDateString('en-GB') : 'Select Date'} // Display the ISO string in the input
                                readOnly // Make it read-only since we're using the date picker
                                onClick={() => setIsEditingDate(true)} // Toggle to edit mode
                              />
                            ) : (
                              <>
                                <DayPicker
                                  mode="single"
                                  autoFocus
                                  captionLayout="dropdown"
                                  selected={selectedDate}
                                  onSelect={(day) => {
                                    if (day) {
                                      setSelectedDate(day);
                                      setMonth(day); // Update the month view when a day is selected
                                      onChange(day.toISOString()); // Call onChange with the ISO string
                                    }
                                    setIsEditingDate(false); // Toggle back to display mode
                                  }}
                                  month={month} // Display the selected date or the current month
                                  onMonthChange={setMonth} // Allow the user to change the month manually
                                  disabled={{ after: maxDate }} // Disable future dates
                                />
                              </>
                            )}
                          </>
                        );
                      }}
                    />
                    {errors.birthDate && <span className="color-red">{errors.birthDate.message}</span>}
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2"></div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                    <input type="checkbox" className="flex-shrink-none" {...register('hasPublicBirthDate')} />
                    <span className="align-self-middle ps-3 ">Public (birth date visible in your profile page)</span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">
                    Gender <span className="color-red">*</span>
                  </div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-between align-items-center ">
                    <div>
                      <input value="N" type="radio" {...register('gender', { required: 'Gender is required' })} />
                      <label className="align-self-middle fw-normal ">N/A</label>
                    </div>
                    <div>
                      <input type="radio" value="M" {...register('gender', { required: 'Gender is required' })} />
                      <label className="align-self-middle fw-normal ">Male</label>
                    </div>
                    <div>
                      <input type="radio" value="F" {...register('gender', { required: 'Gender is required' })} />
                      <label className="align-self-middle fw-normal ">Female</label>
                    </div>
                    <div>
                      <input type="radio" value="O" {...register('gender', { required: 'Gender is required' })} />
                      <label className="align-self-middle fw-normal ">Other</label>
                    </div>
                  </div>
                </div>
              </>
            )}
            {user?.userType === 'C' && (
              <div className="row mb-5">
                <div className="col-12 col-md-2 text fw-bold">
                  Company description <span className="color-red">*</span>
                </div>
                <div className="col-12 col-md-10">
                  <textarea
                    placeholder={'Please write here a company description'}
                    rows={7}
                    {...register('description', {
                      required: 'Company description is required',
                    })}
                  />
                  {errors.description && <span className="color-red">{errors.description.message}</span>}
                </div>
              </div>
            )}

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Address <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input type="text" {...register('addressLine1', { required: 'Address is required' })} />
                {errors.addressLine1 && <span className="color-red">{errors.addressLine1.message}</span>}
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                City <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input type="text" {...register('city', { required: 'City is required' })} />
                {errors.city && <span className="color-red">{errors.city.message}</span>}
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input type="checkbox" {...register('hasPublicCity')} className="flex-shrink-none" />
                <span className="align-self-middle ps-3 ">Public (city visible in your profile page)</span>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                State/ Province/ Region<span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input type="text" {...register('state', { required: 'State/ Province/ Region is required' })} />
                {errors.state && <span className="color-red">{errors.state.message}</span>}
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Zip Code <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  {...register('zipCode', {
                    required: 'Zip Code is required',
                  })}
                />
                {errors.zipCode && <span className="color-red">{errors.zipCode.message}</span>}
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Your current country <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: 'Country is required' }} // Add required validation rule
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      <CountrySelect value={value} onChange={onChange} />
                      {error && <span className="color-red">{error.message}</span>} {/* Display validation error */}
                    </>
                  )}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input type="checkbox" className="flex-shrink-none" {...register('hasPublicCountry')} />

                <span className="align-self-middle ps-3 ">Public (country visible in your profile page)</span>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">Nationality</div>
              <div className="col-12 col-md-10">
                <Controller
                  name="nationality"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      <CountrySelect value={value} onChange={onChange} />
                      {error && <span className="color-red">{error.message}</span>} {/* Display validation error */}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input type="checkbox" className="flex-shrink-none" {...register('hasPublicNationality')} />

                <span className="align-self-middle ps-3 ">Public (nationality visible in your profile page)</span>
              </div>
            </div>

            {user?.userType === 'D' && (
              <>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Contact language</div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-between align-items-center ">
                    <div>
                      <input type="radio" value="E" {...register('contactLanguage')} />
                      <label className="align-self-middle fw-normal ">English</label>
                    </div>
                    <div>
                      <input type="radio" value="I" {...register('contactLanguage')} />
                      <label className="align-self-middle fw-normal ">Italian</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">
                    Mobile Number
                  </div>
                  <div className="col-12 col-md-10">
                    <input type="text" {...register('mobileNumber')} />
                    {errors.mobileNumber ? <span className="color-red">{errors.mobileNumber.message}</span>:<span className=" ms-2 color-dark_grey_40">The phone number will be kept confidential and will be used in case of winning for any urgent communications</span>}
                  </div>
                </div>
              </>
            )}
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">Tags</div>
              <div className="col-12 col-md-10">
                <Controller name="tags" control={control} render={({ field: { onChange, value } }) => <TagEditor tags={value || []} onChange={onChange} />} />
              </div>
            </div>

            <div className="row  d-flex justify-content-center align-items-center mb-7 mt-7">
              <div className={`btn-dark_blue col-md-2 mb-sm-3 mb-md-0 rounded mx-5 ${styles.button} `} onClick={handleSubmit(gotoProfilePreview)}>
                preview
              </div>
              <button className={`btn-success rounded col-md-2 mx-5 ${styles.button}  `}>update</button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default YourProfileWLF;
