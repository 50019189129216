import { useState } from 'react';
import { User } from '../../../global';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import axiosHelper from '../../../helpers/axiosHelper';

import styles from './YourProfessionalProfile.module.scss';
import JobSelect from '../../Forms/DropDown/JobSelect';
import SocialEditor from './SocialEditor';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface Props {
  user: User;
  callback: (user: User) => void;
}

const YourProfessionalProfileWLF: React.FC<Props> = ({ user, callback }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Partial<User>>({
    defaultValues: user, // Pre-fill form with the user's data
  });
  const [opened, setOpened] = useState(false);
  const openClicked = () => {
    console.log('openClicked');
    setOpened(!opened);
  };

  const gotoProfilePreview: SubmitHandler<Partial<User>> = (data) => {
    window.open(`/${ROUTEPATHS.COMMUNITY}/${user.nameForURL}/profile`, 'blank');
  };

  const onSubmit: SubmitHandler<Partial<User>> = async (data) => {
    const userdata = await axiosHelper.updateDSLUser(data);
    if (userdata) {
      callback(userdata);
    }
  };

  return (
    <>
      <div
        className="col-12  bg-blue mb-5 align-items-center justify-content-center  d-flex"
        style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
        onClick={openClicked}
      >
        <div className="col-2"></div>
        <div className="col-8 justify-content-center d-flex ">
          <span className="text-xxl fw-bold color-light" style={{ fontSize: '2rem' }}>
            Your professional profile
          </span>
        </div>

        <div className={`col-2 d-flex justify-content-end align-items-center ${styles.toggle} ${opened && styles.open}`} onClick={openClicked}>
          <div className={`${styles.arrow} `}> </div>
        </div>
      </div>
      {opened && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-5 mt-7">
            <div className="row mb-7 mt-4">
              <div className="col-12 col-md-2 text fw-bold"></div>
              <div className="col-12 col-md-10">
                <span className="text color-blue">
                  <i>
                    <b>
                      <span className="color-red">*</span> &nbsp; Mandatory fields (required to {user?.userType === 'D' ? 'upload your design' : 'launch a contest'})
                    </b>
                  </i>
                </span>
              </div>
            </div>

            {user.userType === 'D' && (
              <div className="row mb-5">
                <div className="col-12 col-md-2 text fw-bold">
                  Who are you <span className="color-red">*</span>
                </div>
                <div className="col-12 col-md-10">
                  <Controller
                    name="job"
                    control={control}
                    rules={{ required: 'Job is required' }} // Add required validation rule
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <>
                        <JobSelect value={value} onChange={onChange} />
                        {error && <span className="color-red">{error.message}</span>} {/* Display validation error */}
                      </>
                    )}
                  />
                </div>
              </div>
            )}
            {user?.userType === 'C' && (
              <>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Company name</div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      {...register('companyName', {
                        required: false,
                      })}
                    />
                    {errors.companyName && <span className="color-red">{errors.companyName.message}</span>}
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">
                    Sector <span className="color-red">*</span>
                  </div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      {...register('sector', {
                        required: 'Sector is required',
                      })}
                    />
                    {errors.sector && <span className="color-red">{errors.sector.message}</span>}
                  </div>
                </div>
              </>
            )}
            {user.userType === 'D' && (
              <>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Job title</div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      {...register('jobTitle', {
                        required: false,
                      })}
                    />
                    {errors.jobTitle && <span className="color-red">{errors.jobTitle.message}</span>}
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Company</div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      {...register('companyName', {
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">School/ University</div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      {...register('school1', {
                        required: false,
                      })}
                    />
                    {errors.school1 && <span className="color-red">{errors.school1.message}</span>}
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2"></div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                    <input type="checkbox" className="flex-shrink-none" {...register('hasPublicFirstSchool')} />
                    <span className="align-self-middle ps-3 ">Public (School/ University visible in your profile page)</span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Other School/ University </div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      {...register('school2', {
                        required: false,
                      })}
                    />
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2"></div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                    <input type="checkbox" className="flex-shrink-none" {...register('hasPublicSecondSchool')} />
                    <span className="align-self-middle ps-3 ">Public (Other School/ University visible in your profile page)</span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">License to work as freelancer</div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-between align-items-center ">
                    <div>
                      <input type="radio" value="Y" {...register('freelanceLicense')} />
                      <label className="align-self-middle fw-normal ">Yes</label>
                    </div>
                    <div>
                      <input type="radio" value="F" {...register('freelanceLicense')} />
                      <label className="align-self-middle fw-normal ">No</label>
                    </div>
                  </div>
                  {errors.freelanceLicense && <span className="color-red">{errors.freelanceLicense.message}</span>}
                </div>
              </>
            )}
            <div className="row mb-5">
              <div className="col-12 col-md-2 mb-3 text fw-bold">Website</div>
              <div className="col-12 col-md-10">
                <input type="text" {...register('website', { validate: (value) => !value || value.startsWith('https://') || 'URL must start with https://' })} />
                {errors.website && <span className="color-red">{errors.website.message}</span>}
              </div>
            </div>

            <Controller name="socialNetworks" control={control} render={({ field: { value, onChange } }) => <SocialEditor socialNetworks={value || []} onChange={onChange} />} />

            <div className="row  d-flex justify-content-center align-items-center mb-7 mt-7">
              <div className={`btn-dark_blue col-md-2 mb-sm-3 mb-md-0 rounded mx-5 ${styles.button} `} onClick={handleSubmit(gotoProfilePreview)}>
                preview
              </div>

              <button className={`btn-success rounded col-md-2 mx-5 ${styles.button}  `}>update</button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default YourProfessionalProfileWLF;
